<template>
    <defaultSec v-loading="loadingFull" class="refund-detail" :title="'< 返回'" :returnState="true">
        <el-alert
            v-if="orderSource == 27 || orderSource == 40"
            style="margin-bottom: 10px"
            title="奶卡【卡订单】退款说明"
            type="warning"
            :closable="false"
            show-icon>
            警告：此订单是购买奶卡的订单，同意退款会自动禁用该奶卡，同时作废所有未发货的计划！！<br/>
            卡面值:{{cardInfo.totalAccount}},已发货{{cardInfo.deliveredAmount}},未发货{{cardInfo.unDeliveredAmount}},剩余{{cardInfo.usableAccount}},退款比例:{{cardInfo.rate}},下方退款金额已按比例计算过，若与实际情况不符，可手动更改
        </el-alert>
        <el-alert
            v-if="orderSource == 26"
            style="margin-bottom: 10px"
            title="奶卡【次卡】【实物订单】退款说明"
            type="warning"
            :closable="false"
            show-icon>
            警告：此订单是次卡的实物订单，需要询问客户诉求：<br/>
            1、部分或全部商品有问题需要退钱：同意退款后，此单将申请售后，同时，卡订单也将自动生成售后单，退相同金额；<br/>
            2、不需要退钱，需要补发，可以创建补发单或者给用户赠品池添加需要补发的商品，同时这一单拒绝退款；<br/>
            注意：该退款不会禁用奶卡，也不会作废未发货的计划。
        </el-alert>
        <el-alert
            v-if="orderSource == 36"
            style="margin-bottom: 10px"
            title="奶卡【点卡/新奶卡】【实物订单】退款说明"
            type="warning"
            :closable="false"
            show-icon>
            警告：此订单是点卡或新奶卡的实物订单，同意退款会把这单使用的点数/奶卡余额退还到卡里。<br/>
            注意：该退款不会禁用奶卡，也不会作废未发货的计划。
        </el-alert>
        <!-- 奶卡实物单退款，退款金额为0不能修改，需要单独提供一个输入退款金额的地方 -->
        <el-descriptions class="margin-top" title="订单详情" :column="2" size="small" border style="margin-bottom: 20px">
            <template slot="extra">
                <el-button size="mini" v-if="orderSource == 26 && pageType == 3" type="primary" @click="toCheckRefundByBufa('agree')">同意补发
                </el-button>
                <el-button size="mini" v-if="orderSource == 26 && pageType == 3" type="primary" @click="toCheckRefundByRefundMoney('agree')">同意退钱
                </el-button>
                <el-button size="mini" v-if="orderSource != 26 && pageType == 3" type="primary" @click="toCheckRefund('agree')">同意退款
                </el-button>
                <el-button size="mini" v-if="pageType == 3" type="danger" @click="toCheckRefund('disagree')">拒绝退款
                </el-button>
            </template>
            <el-descriptions-item>
                <template slot="label">
                    售后单号
                </template>
                {{ detailInfo.refundNo || '' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    外部单号
                </template>
                {{ detailInfo.orderNo }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    退款平台
                </template>
                {{ detailInfo.dataPlatform == 'wx_mall' ? '微信商城' : '其他' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    售后类型
                </template>
                {{ detailInfo.afterSaleType == '1' ? '未发货退款' : '已发货退款' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <i class="el-icon-tickets"></i>
                    是否退优惠券
                </template>
                {{ detailInfo.isRefundCoupon == 1 ? '是' : '否' }}
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="margin-top" title="用户申请" :column="2" size="small" border style="margin-bottom: 20px">
            <el-descriptions-item>
                <template slot="label">
                    退款类型
                </template>
                {{ detailInfo.refundType == 1 ? '仅退款' : '退货退款' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    退款原因
                </template>
                {{ detailInfo.refundReason || '' }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    申请时间
                </template>
                {{ detailInfo.createTime }}
            </el-descriptions-item>
            <el-descriptions-item v-if="orderSource != 26 && orderSource != 36">
                <template slot="label">
                    退款金额
                </template>
                {{ detailInfo.refundAmountApply }}
            </el-descriptions-item>
            <el-descriptions-item v-if="orderSource == 26 || orderSource == 36">
                <template slot="label">
                    奶卡退款金额
                </template>
                {{ detailInfo.cardPrice }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    补充描述
                </template>
                {{ detailInfo.remark }}
            </el-descriptions-item>
        </el-descriptions>

<!--        <el-descriptions class="margin-top" v-show="orderSource == 45" title="付费会员订单退款" :column="2" size="small" border style="margin-bottom: 20px">
            <el-descriptions-item>
                <template slot="label">
                    开始时间
                </template>
&lt;!&ndash;                {{ mallVipTimeDTO.beginTime }}&ndash;&gt;
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    结束时间
                </template>
                {{ mallVipTimeDTO.endTime }}
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    购买时间
                </template>
                {{ mallVipTimeDTO.createTime }}
            </el-descriptions-item>
            <el-descriptions-item v-if="orderSource != 26 && orderSource != 36">
                <template slot="label">
                    已使用天数
                </template>
                {{ mallVipTimeDTO.usedDayNum }}
            </el-descriptions-item>

            <el-descriptions-item v-if="orderSource == 26 || orderSource == 36">
                <template slot="label">
                    剩余天数
                </template>
                {{ mallVipTimeDTO.remainDayNum }}
            </el-descriptions-item>
            <el-descriptions-item >
                <template slot="label">
                    会员等级
                </template>
                {{ mallVipTimeDTO.level }}
            </el-descriptions-item>
            <el-descriptions-item >
                <template slot="label">
                    退款状态
                </template>
                {{ mallVipTimeDTO.status==1?'有效':'无效' }}
            </el-descriptions-item>
        </el-descriptions>-->

        <tablePagination :total="0" :pageSize="15">
            <h4>商品明细</h4>
            <el-table :data="detailInfo.refundProductResVoList" border size="mini">
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="refundNo" label="退款编号"></el-table-column>
                <el-table-column prop="productId" label="产品id"></el-table-column>
                <el-table-column prop="productName" label="产品名称"></el-table-column>
                <el-table-column prop="payAmount" label="产品实付金额">
                    <template slot-scope="scope">
                        <div style="font-size: 10px;" v-if="orderSource != 26 && orderSource != 36">
                            总金额: {{scope.row.payAmount}}
                        </div>
                        <div style="font-size: 10px;" v-if="scope.row.refundCashAmountApplyOrigin">
                            微信部分: {{scope.row.refundCashAmountApplyOrigin}}
                        </div>
                        <div style="font-size: 10px;" v-if="scope.row.refundBalanceAmountApplyOrigin && orderSource != 26 && orderSource != 36">
                            余额部分: {{scope.row.refundBalanceAmountApplyOrigin}}
                        </div>
                        <div style="font-size: 10px;" v-if="scope.row.cardPrice && (orderSource == 26 || orderSource == 36)">
                            奶卡部分: {{scope.row.cardPrice}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="productNum" label="退货数量" min-width="150">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.productNum"
                                         @change="handleChangeProductNum(scope.row)" :min="0"
                                         :disabled="productNumDisabled">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column min-width="150" v-if="orderSource != 26 && orderSource != 36" label="现金退款金额">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.refundCashAmountApply" :min="0"
                                         :max="scope.row.productCashAmountMax" :step="0.5"
                                         :disabled="refundCashAmountApplyDisabled">
                        </el-input-number>
                    </template>
                </el-table-column>

                <el-table-column min-width="150" v-if="orderSource != 26 && orderSource != 36" label="余额退款金额">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.refundBalanceAmountApply" :min="0"
                                         :max="scope.row.productBalanceAmountMax" :step="0.5"
                                         :disabled="refundBalanceAmountApplyDisabled">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column min-width="150" v-if="orderSource == 26 || orderSource == 36" label="奶卡退款金额">
                    <template slot-scope="scope">
                        <el-input-number size="mini" v-model="scope.row.cardPrice" :min="0"
                                         :max="scope.row.cardPrice" :step="0.5">
                        </el-input-number>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="danger" v-if="pageType == 1" plain size="mini" icon="el-icon-delete"
                                   @click="delProduct(scope.$index)" circle :disabled="delProductDisabled"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
    </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import tablePagination from '@c/tablePagination'

    export default {
        data() {
            return {
                refundNo:this.$route.params.refundNo,
                orderNo:this.$route.params.orderNo,
                pageType:this.$route.params.pageType,
                orderSource:this.$route.params.orderSource,
                fromPage:this.$route.params.fromPage,
                msgCode:'',
                detailInfo:{
                },
                mallVipTimeDTO:{},
                loadingFull:true,
                refundProductResVoList:[],
                arr:[
                    {label:'否',value:2},
                    {label:'是',value:1}
                ],
                total:0,
                refundTypes:[
                    {value:1,label:'退款'},
                    {value:2,label:'退货退款'}
                ],
                refundTypeDisabled:false,
                productNumDisabled:false,
                refundCashAmountApplyDisabled:false,
                refundBalanceAmountApplyDisabled:false,
                applyRefundDisabled:false,
                isRefundCouponDisabled:false,
                delProductDisabled:false,
                dataPlatform:'',
                cardInfo:{}
            }
        },
        components:{
            defaultSec,tablePagination
        },
        mounted(){
            this.initList(this.refundNo,this.orderNo,this.pageType,this.orderSource)
        },
        methods: {
            initList(refundNo,orderNo,pageType,orderSource) {
                if(refundNo == "undefined"){
                    refundNo = "";
                }
                if(pageType == 2 || pageType == 3){
                    // 如果是天猫售后单可以改：删除
                    this.delProductDisabled=true;
                }
                this.$apiGet('lechun-csms/refundApi/queryRefundList',{refundNo,orderNo,pageType,orderSource}).then(res => {
                    this.detailInfo = res.refunds.length>0?res.refunds[0]:{}
                    this.detailInfo.refundProductResVoList = res.refunds[0].refundProductResVoList;
                    this.mallVipTimeDTO=res.refunds[0].vipTimeDTO
                    console.log(this.detailInfo.afterSaleType);
                    this.loadingFull = false
                    if(this.detailInfo.afterSaleType == "1"){ //售中退款(未发货退款)
                        this.detailInfo.refundType = this.refundTypes[0].value;
                        // 如果是天猫售后单可以改：数量
                        this.productNumDisabled=true;
                        // 如果是天猫售后单可以改：金额
                        this.refundCashAmountApplyDisabled=true;
                        this.refundBalanceAmountApplyDisabled=true;
                    }
                    if(this.detailInfo.refundNumAmountMax <= 0){
                        this.applyRefundDisabled=true;
                    }
                    if(this.detailInfo.couponPay == false){
                        this.isRefundCouponDisabled = true;
                        this.detailInfo.isRefundCoupon = this.arr[0].value;
                    }
                    this.dataPlatform = res.refunds[0].dataPlatform;
                    if(res['cardInfo']){
                        this.cardInfo = res['cardInfo'];
                        this.detailInfo.refundProductResVoList.forEach(vo => {
                            vo.refundCashAmountApplyOrigin = vo.refundCashAmountApply;
                            vo.refundBalanceAmountApplyOrigin = vo.refundBalanceAmountApply;
                            vo.refundCashAmountApply = Math.round(vo.refundCashAmountApply * this.cardInfo.rate * 100) / 100;
                            vo.refundBalanceAmountApply = Math.round(vo.refundBalanceAmountApply * this.cardInfo.rate * 100) / 100;
                        })
                    }
                    if(this.detailInfo.orderSource == 26){
                        this.detailInfo.refundProductResVoList.forEach(vo => {
                            vo.refundCashAmountApplyOrigin = vo.refundCashAmountApply;
                            vo.refundBalanceAmountApplyOrigin = vo.refundBalanceAmountApply;
                            vo.refundCashAmountApply = 0;
                            vo.refundBalanceAmountApply = 0;
                        })
                    }
                })
            },
            handleChangeProductNum(row){
                row.refundCashAmountApply=row.productNum*row.productPrice;
            },
            toCheckRefundByBufa(agreeStatus){
                // 先同意退款，不退卡订单，提示用户补发，跳转到订单详情页
                this.$confirm('该操作需要您手动创建补发单，将跳转订单详情页面，是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.detailInfo.refundProductResVoList.forEach(vo => {
                        vo.cardPrice = 0;
                    })
                    this.checkRefundNoJump(agreeStatus);
                }).catch(() => {

                });
            },
            toCheckRefundByRefundMoney(agreeStatus){
                // 同意退款，同时退卡订单
                var cardPrice = 0;
                this.detailInfo.refundProductResVoList.forEach(vo => {
                    cardPrice += vo.cardPrice;
                    vo.refundCashAmountApply = 0;
                    vo.refundBalanceAmountApply = 0;
                })
                this.$confirm('该操作将会在购买卡的订单上退' + cardPrice + '元，是否继续', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.checkRefund(agreeStatus);
                }).catch(() => {

                });
            },
            toCheckRefund(agreeStatus){
                this.checkRefund(agreeStatus);
            },
            checkRefund(agreeStatus){
                var cardPrice = 0;
                this.detailInfo.refundProductResVoList.forEach(vo => {
                    cardPrice += vo.cardPrice;
                })
                var formJson = {
                    refundNo:this.detailInfo.refundNo,
                    isRefundCoupon:this.detailInfo.isRefundCoupon,
                    refundReason: this.detailInfo.refundReason,
                    orderNo: this.detailInfo.orderNo,
                    orderSource: this.orderSource,
                    orderMainNo: this.detailInfo.orderMainNo,
                    specialCardRefundAmount: cardPrice,
                    refundProductList:this.detailInfo.refundProductResVoList
                };
                this.loadingFull = true
                console.log(JSON.stringify(formJson));
                this.$apiPost('lechun-csms/refundApi/refundCheck?agreeStatus=' + agreeStatus + '&msgCode=' + this.msgCode,formJson).then(res=>{
                    this.loadingFull = false
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    //跳转到退款列表页
                    window.history.go(-1);
                })
            },
            checkRefundNoJump(agreeStatus){
                var cardPrice = 0;
                this.detailInfo.refundProductResVoList.forEach(vo => {
                    cardPrice += vo.cardPrice;
                })
                var formJson = {
                    refundNo:this.detailInfo.refundNo,
                    isRefundCoupon:this.detailInfo.isRefundCoupon,
                    refundReason: this.detailInfo.refundReason,
                    orderNo: this.detailInfo.orderNo,
                    orderSource: this.orderSource,
                    orderMainNo: this.detailInfo.orderMainNo,
                    specialCardRefundAmount: cardPrice,
                    refundProductList:this.detailInfo.refundProductResVoList
                };
                this.loadingFull = true
                console.log(JSON.stringify(formJson));
                this.$apiPost('lechun-csms/refundApi/refundCheck?agreeStatus=' + agreeStatus + '&msgCode=' + this.msgCode,formJson).then(res=>{
                    this.$router.push({
                        path: '/orderList/:externalOrderNo',
                        name: 'orderDetail',
                        params: {externalOrderNo: this.detailInfo.orderNo}
                    })
                })
            },
            delProduct(index){
                console.log(index)
                this.detailInfo.refundProductResVoList.splice(index,1)
            }
        }
    }
</script>


<style lang="scss" rel="stylesheet/scss">

    .refund-detail{
        .red .el-form-item__label{
            color: red;
        }
        .el-form-item__label{
            font-size: 12px!important;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag{
            width: 100%;
            height: 32px;
        }
        .el-avatar{
            display: block;
            margin: 0 auto;
        }
    }
</style>